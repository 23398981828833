import React from "react";
import Headline from "./Headline";
import Paragraph from "./Paragraph";
import {ServiceImages} from "../Types";

const Service: React.FC<Props> = (
  {
    header,
    text,
    icon

  }
) => {
  return (
    <div className="flex flex-col items-center justify-start 2xl:w-[400px] w-[100%]">
      <div className={`bg-image ${icon} w-[100px] h-[100px]`}/>
      <Headline size="h3" color="quaternary">{header}</Headline>
      <div className="w-full h-0.5 my-5 px-10"><div className="bt-1 h-0.5 solid bg-white"/></div>
      <Paragraph color="text-quaternary" size="text-white" textAlign="text-center">{text}</Paragraph>
    </div>
  );
};

export default Service;

interface Props {
  header: string,
  text: string,
  icon?:ServiceImages
}