import React from "react";
import Headline from "../Components/Headline";
import Service from "../Components/Service";
import OutlinedButton from "../Components/OutlinedButton";

const Services: React.FC = () => {
  return(
    <div className="bg-primary py-12 px-3">
      <Headline size="h2" color="quaternary" textAlign="text-center">Benvenuto nel nostro laboratorio</Headline>
      <div className="flex flex-col items-center justify-center py-6">
        <div className="flex 2xl:flex-row flex-col gap-10">
          <Service icon="bg-service-scissors" header="Zero Time Hair" text="Shampoo, taglio, panno rigenerante e styling"/>
          <Service icon="bg-service-scissors" header="MR Trewan Hair" text="Shampoo, taglio, panno rigenerante profumato massaggio di collo e spalle, fragranza Armonia risciacquo finale e styling"/>
          <Service icon="bg-service-scissors" header="L’Experience Hair" text="Fitoargilla con massaggio immersivo, shampoo, taglio,  trattamento Seremony intenso con panni caldi profumati e fragranza Armonia"/>
        </div>
        <div className="flex 2xl:flex-row flex-col gap-10 mt-6">
          <Service icon="bg-service-razor" header="Zero time Beard" text="Definizione o rasatura della barba panno caldo profumato fragranza Naturale"/>
          <Service icon="bg-service-razor" header="MR Trewan Beard" text="Regolazione o rasatura della barba triplo panno caldo profumato olio di mandorla addolcente fragranza Armonia"/>
          <Service icon="bg-service-razor" header="L’Experience Beard" text="Regolazione e rasatura della barba triplo panno caldo profumato trattamento purificante viso con fitoargilla massaggio immersivo olio di mandorla addolcente fragranza Armonia"/>
        </div>
      </div>
      <div className="p-12">
        <OutlinedButton href={"/Prezzario-Web.pdf"} downloadName={"Menu MR Trewan"} download={true} color="white">Scopri di più</OutlinedButton>
      </div>
    </div>
  )
};

export default Services;