import React, {ReactNode} from "react";
import {Colors} from "../Types";

const colors = Object.freeze<Record<'white' | 'primary' | 'secondary' | 'tertiary' | 'quaternary' | 'quinary', string>>({
  white: 'text-white',
  primary: 'text-primary',
  secondary: 'text-secondary',
  tertiary: 'text-tertiary',
  quaternary: 'text-quaternary',
  quinary: 'text-quinary',
});

const Headline: React.FC<Props> = (
  {
    children,
    size = 'base',
    color = 'white',
    textAlign = 'text-left'
  }
) => {
  switch (size) {
    case "h1":
      return <h1
        className={`text-5xl ${colors[color]} ${textAlign}`}
      >
        {children}
      </h1>;

    case "h2":
      return <h2
        className={`text-4xl ${colors[color]} ${textAlign}`}
      >
        {children}
      </h2>;

    case "h3":
      return <h3
        className={`text-3xl ${colors[color]} ${textAlign}`}
      >
        {children}
      </h3>;

    case "h4":
      return <h4
        className={`text-2xl ${colors[color]} ${textAlign}`}
      >
        {children}
      </h4>;

    default:
      return <h1
        className={`text-5xl ${colors[color]} ${textAlign}`}
      >
        {children}
      </h1>;
  }
};

export default Headline;

interface Props {
  children?: ReactNode;
  size?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
  color?: Colors;
  textAlign?: 'text-left' | 'text-center' | 'text-right';
};