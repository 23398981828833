import React from "react";
import OutlinedButton from "../Components/OutlinedButton";
const Header: React.FC = () => {
  return(
    <div className="bg-header-background bg-image w-full h-[90vh] flex 2xl:items-center items-end 2xl:justify-start justify-center">
      <div className="left-0 2xl:ml-40 ml-0 2xl:mb-0 mb-10 flex flex-col items-center justify-center">
        <div className="h-[200px] w-[300px] bg-logo-white bg-image"/>
        <OutlinedButton href={"https://tribeauty.it/MR.TREWAN"} download={false} color="white">Prenota Ora</OutlinedButton>
      </div>
    </div>
  );
};

export default Header;